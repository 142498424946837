import { TransactionBlock } from "@mysten/sui.js/transactions";
import { MIST_PER_SUI } from "@mysten/sui.js/utils";
import { SuiClient, getFullnodeUrl } from "@mysten/sui.js/client";

export const suiServiceFunctions = () => {
  const walletConnectedCheck = (wallet) => wallet.connected;

  const newTxBlock = () => new TransactionBlock();

  const createTransactionBlock = (
    contractAddress,
    contractModule,
    contractMethod,
    moveCallTypeArgument,
    moveCallArguments
  ) => {
    const tx = new TransactionBlock();

    tx.moveCall({
      target: `${contractAddress}::${contractModule}::${contractMethod}`,
      typeArguments: moveCallTypeArgument,
      arguments: moveCallArguments.map((indArg) => tx.object(indArg)),
    });
    return tx;
  };

  const executeTransactionBlock = async (tx, wallet) => {
    try {
      const res = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: tx,
      });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  };

  const mistToSui = (amtInMist) =>
    Number.parseInt(amtInMist) / Number(MIST_PER_SUI);

  const suiToMist = (amtInSui) => Number(MIST_PER_SUI) * Number(amtInSui);

  const client = () => new SuiClient({ url: getFullnodeUrl("testnet") });

  const suiReadAndWriteApi = async (methodName, argObj) =>
    await client()[methodName](argObj);

  return {
    walletConnectedCheck,
    newTxBlock,
    createTransactionBlock,
    executeTransactionBlock,
    suiToMist,
    mistToSui,
    suiReadAndWriteApi,
  };
};

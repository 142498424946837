import React, { useState } from "react";
import SideBar from "../../../components/Sidebar";
import styles from "./newDeal.module.css";
import DealSuccess from "../../../components/DealSuccess";
import { Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageOne from "../../../assets/images/PageOne.svg";
import suiIcon from "../../../assets/icons/SuiLogo.svg";
import {
  ROUTES,
  TOKEN,
  TOAST_RESPONSE,
  API_METHODS,
  TOKEN_DETAILS,
  CONTRACT_ADDRESSES,
  CONTRACT_FUNCTIONS,
  SUI_READ_WRITE_METHODS,
} from "../../../utils/constants.utils";
import { APIcall, toastMessage } from "../../../utils/helper.utils";
import Header from "../../../components/Header";
import { useAuth } from "../../../context/authContext";
import { suiServiceFunctions } from "../../../services/sui.services";
import { useWallet } from "@suiet/wallet-kit";

const NewDeal = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dealLink, setDealLink] = useState("");
  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  const wallet = useWallet();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      amount: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(5, "Title length must be at least 5 characters long")
        .required("Title is required"),
      description: Yup.string()
        .min(10, "Description length must be at least 10 characters long")
        .required("Description is required"),
      amount: Yup.number().required("USDT is required"),
    }),
    onSubmit: async (values) => {
      try {
        if (!suiServiceFunctions().walletConnectedCheck(wallet)) return;

        setDisableBtn(true);

        if (
          values.amount >
          suiServiceFunctions().mistToSui(
            (
              await suiServiceFunctions().suiReadAndWriteApi(
                SUI_READ_WRITE_METHODS.FETCH_TOTAL_COIN_BALANCE,
                {
                  owner: wallet.address,
                  coinType: TOKEN_DETAILS.SUI.USDT,
                }
              )
            ).totalBalance
          )
        )
          throw new Error("Insufficient USDT!");

        const usdtCoinsArr = (
          await suiServiceFunctions().suiReadAndWriteApi(
            SUI_READ_WRITE_METHODS.FETCH_COINS_OWNED_BY_ADDR,
            {
              owner: wallet.address,
              coinType: TOKEN_DETAILS.SUI.USDT,
            }
          )
        ).data;

        const tx = suiServiceFunctions().newTxBlock();

        if (usdtCoinsArr.length > 1)
          tx.mergeCoins(
            tx.object(usdtCoinsArr[0].coinObjectId),
            usdtCoinsArr.slice(1).map((ele) => tx.object(ele.coinObjectId))
          );

        const response = await APIcall(
          API_METHODS.POST,
          ROUTES.SUIDEALS.postDeal,
          {
            deal_title: values.title,
            deal_description: values.description,
            deal_token: TOKEN.USDT,
          }
        );

        tx.moveCall({
          target: `${CONTRACT_ADDRESSES.SUI.ESCROW.ESCROW_PACKAGE_ID}::${CONTRACT_ADDRESSES.SUI.ESCROW.ESCROW_MODULE}::${CONTRACT_FUNCTIONS.SUI.INTIALIZE_DEAL}`,
          typeArguments: [TOKEN_DETAILS.SUI.USDT],
          arguments: [tx.pure(response.data.deal_id)],
        });

        const executeInitRes =
          await suiServiceFunctions().executeTransactionBlock(tx, wallet);

        if (executeInitRes)
          toastMessage(
            "Escrow initialization successfull!",
            "init_success",
            TOAST_RESPONSE.SUCCESS
          );

        const transactionBlockRes =
          await suiServiceFunctions().suiReadAndWriteApi(
            SUI_READ_WRITE_METHODS.TX_BLOCK_RESULT,
            {
              digest: executeInitRes.digest,
              options: {
                showEvents: true,
              },
            }
          );

        const tx1 = suiServiceFunctions().newTxBlock();

        const [coin] = tx1.splitCoins(
          tx1.object(usdtCoinsArr[0].coinObjectId),
          [tx1.pure(suiServiceFunctions().suiToMist(values.amount))]
        );

        tx1.moveCall({
          target: `${CONTRACT_ADDRESSES.SUI.ESCROW.ESCROW_PACKAGE_ID}::${CONTRACT_ADDRESSES.SUI.ESCROW.ESCROW_MODULE}::${CONTRACT_FUNCTIONS.SUI.DEPOSIT}`,
          typeArguments: [TOKEN_DETAILS.SUI.USDT],
          arguments: [
            coin,
            tx1.object(CONTRACT_ADDRESSES.SUI.CLOCK),
            tx1.object(transactionBlockRes.events[0].parsedJson.id),
          ],
        });

        if (await suiServiceFunctions().executeTransactionBlock(tx1, wallet))
          toastMessage(
            "Transaction deposit success!",
            "deposit_success",
            TOAST_RESPONSE.SUCCESS
          );

        setDealLink(response.data?.deal_link);
        setIsSubmitted(true);
      } catch (error) {
        toastMessage(error.message, "error", TOAST_RESPONSE.ERROR);
      } finally {
        setDisableBtn(false);
      }
    },
  });

  return (
    <React.Fragment>
      <SideBar activeProp="New Deal" />
      <Header account={user} walletIcon={suiIcon} />
      <div className={styles.newDeal}>
        {isSubmitted ? (
          <DealSuccess dealLink={dealLink} />
        ) : (
          <>
            <span className={styles.heading}>Create New Deal</span>
            <div className={`mt-3 ${styles.formDiv}`}>
              <div className={styles.image}>
                <img
                  alt="pageOne"
                  src={PageOne}
                  className={styles.imageWidth}
                />
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.label}>Title</Form.Label>
                  <Form.Control
                    className={styles.customInput}
                    type="text"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <p className={styles.error}>{formik.errors.title}</p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.label}>Description</Form.Label>
                  <Form.Control
                    className={styles.customInput}
                    type="text"
                    as="textarea"
                    rows={2}
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <p className={styles.error}>{formik.errors.description}</p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={styles.label}>
                    {`Amount(in ${TOKEN.USDT})`}
                  </Form.Label>
                  <Form.Control
                    className={styles.customInput}
                    type="number"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <p className={styles.error}>{formik.errors.amount}</p>
                  ) : error ? (
                    <p className={styles.error}>{error}</p>
                  ) : null}
                </Form.Group>
                <Button
                  className={styles.nextBtn}
                  disabled={disableBtn}
                  style={{
                    backgroundColor: disableBtn ? "grey !important" : "",
                  }}
                  type="submit"
                >
                  {disableBtn ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Next"
                  )}
                </Button>
              </Form>
            </div>
            {disableBtn && (
              <span className="mt-3 fs-6">
                Please don't refresh the page! The deal is being processed...
              </span>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default NewDeal;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/authContext";
import SolanaProvider from "./context/solanaProvider";
import { SolanaContextProvider } from "./context/solaServiceContext";
import { ConnectProvider } from "./context/connectContext";
import { WalletProvider, SuiWallet } from "@suiet/wallet-kit";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WalletProvider defaultWallets={[SuiWallet]}>
        <SolanaProvider>
          <SolanaContextProvider>
            <ConnectProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ConnectProvider>
          </SolanaContextProvider>
        </SolanaProvider>
      </WalletProvider>
    </BrowserRouter>
  </React.StrictMode>
);
